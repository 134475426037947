<template>
  <div>
    <template v-if="$options.components['advertisement-loading']">
      <advertisement-loading v-if="isLoading" />
    </template>
    <b-container class="mb-5">
      <b-row>
        <b-row v-for="(bill, i) in billData" :key="i">
          <b-col md="8">
            <template>
              <div class="container">
                <div class="container-2 pt-2 pl-2">
                  <h6 class="number">
                    Electricity Board:
                    <span class="text">{{ $route.params.name }}</span
                    ><img
                      class="edit-icon-style edit"
                      @click="goback"
                      src="img/EditIcon.png"
                      alt
                    />
                  </h6>
                  <h6 class="number">
                    Consumer Number:
                    <span class="text">{{ $route.params.number }}</span>
                  </h6>
                  <span class="name">
                    Customer Name:
                    <span class="text"
                      >{{ bill.userName }} , {{ bill.cellNumber }}</span
                    >
                  </span>
                  <br />
                  <span class="text">
                    Bill Date :
                    <span class="text">{{ bill.billdate }}</span>
                  </span>
                  <br />
                  <span class="text">
                    Due Date :
                    <span class="text">{{ bill.dueDate }}</span>
                  </span>
                </div>
              </div>
            </template>
          </b-col>
          <b-col md="4" mt="5">
            <span class="bill-amount">Bill Amount:</span>
            <span class="amount">₹{{ bill.billAmount }}</span>
            <br />
            <button class="continue-button mt-5" @click="gotocheckout">
              Continue to Pay
            </button>
          </b-col>
        </b-row>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "checkout-billpayment",
  data() {
    return {
      amount: "",
      isLoading: false,
    };
  },
  computed: {
    ...mapState({
      defaultAddress: (state) => state.address.defaultAddress,
      billData: (state) => state.billPayment.billData.data,
    }),
  },
  methods: {
    gotocheckout() {
      this.$router.push({
        name: "service-checkout",
        params: {
          ma_id: this.$route.params.market_id,
          amount: this.billData[0].billAmount,
          name: this.$route.params.name,
          category: this.$route.params.category,
        },
      });
    },

    goback() {
      this.$router.push({
        name: "electricity-bill",
        params: {
          data: {
            number: this.$route.params.number,
            name: this.$route.params.name,
          },
        },
      });
    },
  },
  async mounted() {
    this.isLoading = true;
    const payloadData = {
      cn: this.$route.params.number.trim(),
      op: this.$route.params.id.toString(),
      adParams: {
        ad1: "",
      },
    };
    await this.$store.dispatch("billPayment/fetchBillApi", payloadData);
    this.isLoading = false;
  },
};
</script>
<style scoped>
.edit {
  margin-left: 5px;
}

.container {
  display: flex;
  flex-direction: row;
  height: 60vh;
  justify-content: space-between;
}
.bill-amount {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 38px;
  color: #000000;
}
.line {
  border: 1px solid #272727;
}
.terms-conditions {
  font-style: normal;
  font-weight: 500;
  margin-top: 15px;
  font-size: 16px;
  line-height: 19px;

  color: #000000;
}
.terms-conditions-text {
  font-style: normal;
  margin-top: 5px;
  font-size: 12px;
  line-height: 19px;
  color: #000000;
}
.enter-amount {
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 42px;
  letter-spacing: -0.408px;
  text-transform: capitalize;
  color: #c7c7c7;
}
.amount {
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 38px;
  margin-left: 20px;
  color: #000000;
}
.continue-button {
  box-sizing: border-box;
  width: 370px;
  height: 32px;
  margin-top: 10px;
  color: white;
  background: #272727;
  border-radius: 3px;
}
.total-amount-color {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 38px;
  color: #14982b;
  opacity: 0.99;
}
.total-amount-text {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 38px;
  color: #14982b;
  opacity: 0.99;
}
.text-bill {
  margin-left: 100px;

  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  font-size: 16px;
  text-transform: capitalize;
  color: #808080;
}
.container-2 {
  background: #fdfdfd;
}
.bill-amount {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 38px;
  color: #000000;
}
.enter-amount {
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 42px;
  letter-spacing: -0.408px;
  text-transform: capitalize;
  color: #c7c7c7;
}
.amount {
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 38px;
  margin-left: 20px;
  color: #000000;
}
.continue-button {
  box-sizing: border-box;
  width: 260px;
  height: 52px;
  /* margin-left: 20px; */
  color: white;
  background: #272727;
  border-radius: 3px;
}
.paynow-button {
  box-sizing: border-box;
  width: 360px;
  height: 42px;
  color: white;
  background: #272727;
  border-radius: 3px;
}
.number {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
}
.name {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  text-transform: capitalize;
  color: #808080;
}
.container-2 {
  width: 520px;
  height: 189px;
  left: 94px;
  top: 193px;
  background: #fdfdfd;
}
.text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-transform: capitalize;
  color: #808080;
}
</style>
