var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.$options.components['advertisement-loading'] ? [_vm.isLoading ? _c('advertisement-loading') : _vm._e()] : _vm._e(), _c('b-container', {
    staticClass: "mb-5"
  }, [_c('b-row', _vm._l(_vm.billData, function (bill, i) {
    return _c('b-row', {
      key: i
    }, [_c('b-col', {
      attrs: {
        "md": "8"
      }
    }, [[_c('div', {
      staticClass: "container"
    }, [_c('div', {
      staticClass: "container-2 pt-2 pl-2"
    }, [_c('h6', {
      staticClass: "number"
    }, [_vm._v(" Electricity Board: "), _c('span', {
      staticClass: "text"
    }, [_vm._v(_vm._s(_vm.$route.params.name))]), _c('img', {
      staticClass: "edit-icon-style edit",
      attrs: {
        "src": "img/EditIcon.png",
        "alt": ""
      },
      on: {
        "click": _vm.goback
      }
    })]), _c('h6', {
      staticClass: "number"
    }, [_vm._v(" Consumer Number: "), _c('span', {
      staticClass: "text"
    }, [_vm._v(_vm._s(_vm.$route.params.number))])]), _c('span', {
      staticClass: "name"
    }, [_vm._v(" Customer Name: "), _c('span', {
      staticClass: "text"
    }, [_vm._v(_vm._s(bill.userName) + " , " + _vm._s(bill.cellNumber))])]), _c('br'), _c('span', {
      staticClass: "text"
    }, [_vm._v(" Bill Date : "), _c('span', {
      staticClass: "text"
    }, [_vm._v(_vm._s(bill.billdate))])]), _c('br'), _c('span', {
      staticClass: "text"
    }, [_vm._v(" Due Date : "), _c('span', {
      staticClass: "text"
    }, [_vm._v(_vm._s(bill.dueDate))])])])])]], 2), _c('b-col', {
      attrs: {
        "md": "4",
        "mt": "5"
      }
    }, [_c('span', {
      staticClass: "bill-amount"
    }, [_vm._v("Bill Amount:")]), _c('span', {
      staticClass: "amount"
    }, [_vm._v("₹" + _vm._s(bill.billAmount))]), _c('br'), _c('button', {
      staticClass: "continue-button mt-5",
      on: {
        "click": _vm.gotocheckout
      }
    }, [_vm._v(" Continue to Pay ")])])], 1);
  }), 1)], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }